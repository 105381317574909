@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .tooltip-arrow-down {
    position: absolute;
    left: calc(50% - 14px);
    bottom: -7px;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid #000;
  }
}